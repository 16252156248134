<template>
  <div>
    <q-form ref="editForm">
      <c-card title="상세" class="cardClassDetailForm no-margin">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="document"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveDocument"
              @btnCallback="saveDocumentCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <c-textarea
              :required="true"
              :editable="editable"
              :editheight="editheight"
              :maxHeight="editheight + 'rem'"
              type="editor"
              label=""
              name="contents"
              v-model="document.contents">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingDocumentEditor',
  props: {
    sopFireFightingDocumentClassCd: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        sopFireFightingDocumentClassCd: '',
      },
      document: {
        sopFireFightingDocumentId: '',  // 소방 문서 일련번호
        plantCd: null,  // 사업장코드
        sopFireFightingDocumentClassCd: '',  // 문서 구분 코드
        contents: '',  // 내용
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      },
      editheight: 40,
      editable: false,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    isOld() {
      return Boolean(this.document.sopFireFightingDocumentId)
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingDocument.get.url
      this.saveUrl = transactionConfig.fft.fireFightingDocument.insert.url
      // code setting
      this.$set(this.searchParam, 'plantCd', this.$store.getters.user.plantCd)
      this.$set(this.searchParam, 'sopFireFightingDocumentClassCd', this.sopFireFightingDocumentClassCd)
      // list setting
      this.setSize();
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.detailUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.extend(this.document, _result.data)
      },);
    },
    setSize() {
      if (window.innerHeight > 800) {
        this.editheight = 40
      } else if (window.innerHeight > 500 && window.innerHeight <= 800) {
        this.editheight = 30
      } else if (window.innerHeight <= 500) {
        this.editheight = 20
      }
    },
    saveDocument() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingDocument.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingDocument.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.document.regUserId = this.$store.getters.user.userId;
              this.document.chgUserId = this.$store.getters.user.userId;
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveDocumentCallback(result) {
      this.document.sopFireFightingDocumentId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>